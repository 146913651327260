module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hoxhaj Bauunternehmen GmbH","description":"Herzlich Willkommen bei Hoxhaj Bauunternehmen GmbH - Qualität ist das, was wir verfolgen.","lang":"de","icon":"src/img/favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0187f2715a92d1f1304057d11c0bd3d1"},
    }]
